<template>
  <VDialog
    v-model="couponDialog"
    max-width="350"
  >
    <template #activator="{ props }">
      <div class=" ml-auto">
        <button
          v-if="!currentCoupon"
          class="cursor-pointer text-primary font-semibold py-1"
          v-bind="props"
        >
          Adicionar cupom
        </button>
        <VBtn
          v-else
          v-bind="props"
          size="x-small"
          variant="flat"
          class="bg-green-800 text-white rounded-xl px-4 font-bold"
        >
          Desconto aplicado: {{ currentCoupon.percentage }}%
        </VBtn>
      </div>
    </template>

    <VCard class="!rounded-[20px] p-4">
      <h3 class="font-semibold">
        Aplicar cupom
      </h3>

      <VTextField
        v-model="couponCode"
        label="Cupom"
        placeholder="Digite o cupom de desconto"
        variant="outlined"
        color="primary"
        density="comfortable"
        rounded
        class="mt-4"
        autofocus
        :disabled="isLoadingCoupon"
      />

      <VBtn
        color="primary"
        variant="flat"
        rounded="full"
        :loading="isLoadingCoupon"
        @click="executeApplyCoupon(couponCode)"
      >
        Aplicar
      </VBtn>
    </VCard>
  </VDialog>
</template>

<script lang="ts" setup>
import { useCheckout } from '../../composables/useCheckout'

const couponCode = ref('')
const couponDialog = ref(false)

const executeApplyCoupon = async (couponCode: string) => {
  const res = await applyCoupon(couponCode)
  if (res) {
    couponDialog.value = false
  }
}

const { applyCoupon, isLoadingCoupon, currentCoupon } = useCheckout()
</script>
