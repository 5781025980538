<template>
  <div class="mt-20 w-full">
    <h3 class="font-quincy text-3xl md:text-4xl text-center">
      O que nossos alunos dizem
    </h3>

    <div class="relative max-w-2xl mx-auto mt-8">
      <VCarousel
        v-model="currentProof"
        hide-delimiters
        :show-arrows="false"
        :height="display.smAndDown.value ? 250 : 190"
        class="border-[1px] border-black/30 shadow rounded-xl"
      >
        <VCarouselItem cover v-for="proof in proofs" :key="proof.name">
          <div class="px-3 py-6 md:!px-4 h-full">
            <div class="flex flex-col h-full">
              <p>{{ proof.quote }}</p>

              <div class="flex mt-auto gap-2">
                <img :src="proof.img" class="size-10 rounded-full" />
                <div class="flex flex-col">
                  <span class="text-lg font-semibold">{{ proof.name }}</span>
                  <span class="text-sm text-gray-500">
                    coRedação PREMIUM
                  </span>
                </div>
              </div>
            </div>
          </div>
        </VCarouselItem>
      </VCarousel>

      <div class="flex justify-center gap-2 mt-4">
        <VBtn class="-ml-10" icon variant="elevated" @click="prevProof">
          <VIcon icon="mdi-chevron-left" size="20" />
        </VBtn>
        <VBtn icon variant="elevated" @click="nextProof">
          <VIcon icon="mdi-chevron-right" size="20" />
        </VBtn>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useDisplay } from 'vuetify';

const currentProof = ref(0);

const display = useDisplay()

const nextProof = () => {
  if (currentProof.value >= proofs.length - 1) {
    currentProof.value = 0;
  } else [
    currentProof.value = currentProof.value + 1,
  ]
};

const prevProof = () => {
  if (currentProof.value <= 0) {
    currentProof.value = proofs.length - 1;
  } else [
    currentProof.value = currentProof.value - 1,
  ]
};

const proofs = [
  {
    name: "Maria Luiza",
    quote:
      "Oii! Usei sua plataforma e depois da prova do ENEM transcrevi minha redação pra lá e a nota bateu certinha! Tirei 960! Muito obrigada, gostei bastante da plataforma 🥰",
    img: "/images/feedback/maria.png",
  },
  {
    name: "Jonatas Alves",
    quote:
      "Oi, Raquel! Tô muito feliz, consegui uma conquista muito grande! Saí dos 760 e pulei pro 920, exatamente a nota que o coRedação me deu!",
    img: "/images/feedback/jonatas.png",
  },
  {
    name: "Helloyse Martins",
    quote:
      "Foi no coRedação que descobri meu erro crônico na C3! Corrigi minha redação do ENEM na plataforma e tirei 940, a nota bateu certinho com a oficial!",
    img: "/images/feedback/helloyse.png",
  },
  {
    name: "Laura Moraes",
    quote:
      "Prof, obrigada por todo auxilio e ajuda! O coRedação foi essencial pra eu entender como fazer uma boa redação e sair dos 480 para os 940! Obrigada ❤️",
    img: "/images/feedback/laura.png",
  },
  {
    name: "André Vilela",
    quote:
      "Passando pra dizer que o coRedação é incrível! Faz um mês que comecei a usar e com os comentários detalhados consegui meu primeiro 900 na redação do ENEM",
    img: "/images/feedback/andre.png",
  },
];
</script>
