import { queryOptions } from '@tanstack/vue-query'
import type { PaymentData } from '~/domains/account/queries/listPaymentsQuery'
import { useApi } from '~/domains/global/composables/useApi'

export const getPaymentById = (paymentId: string) => {
  return queryOptions({
    queryKey: ['getPaymentById', paymentId],
    queryFn: async () => {
      const api = useApi()
      const data = await api<PaymentData>('billing/payment/' + paymentId, {
        method: 'GET',
      })

      return data
    },
    staleTime: 0,
    gcTime: 0,
  })
}
