import { useMutation } from '@tanstack/vue-query'
import { toast } from 'vue3-toastify'
import { useCheckout } from '../composables/useCheckout'
import { useApi } from '~/domains/global/composables/useApi'
import type { PaymentData } from '~/domains/account/queries/listPaymentsQuery'

export const usePaymentPixMutation = () => {
  const { hasCreditCardError, currentPaymentData, goToNextStep } = useCheckout()

  return useMutation({
    mutationFn: async (params: {
      cpf: string
      planId: string
      affiliateId: string | null
    }) => {
      hasCreditCardError.value = false
      const api = useApi()

      // Parse CPF
      params.cpf = params.cpf.replace(/\D/g, '')

      try {
        const data = await api<PaymentData>(`/billing/pix/create`, {
          method: 'POST',
          body: JSON.stringify({
            cpf: params.cpf,
            affiliateId: params.affiliateId,
            planId: params.planId,
          }),
        })

        return data
      }
      catch (e: any) {
        // TODO: Add Sentry handling
        throw new Error('Houve um problema ao processar o pagamento. Tente novamente mais tarde.')
      }
    },
    onError: (error) => {
      toast.error(error.message)
    },
    onSuccess: async (data) => {
      currentPaymentData.value = data

      goToNextStep()
    },
  })
}
