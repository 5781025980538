<template>
  <div class="mt-20 w-full flex flex-col items-center">
    <h3 class="font-quincy text-4xl text-center">Perguntas frequentes</h3>

    <div class="w-full max-w-2xl mt-5">
      <VExpansionPanels>
        <VExpansionPanel
          v-for="question in questions"
          :key="question.title"
          rounded="20"
        >
          <template #title>
            <h3 class="font-medium text-base flex items-center gap-2">
              <img src="/images/checkout/question.svg" class="size-6" />
              <span>{{ question.title }}</span>
            </h3>
          </template>

          <template #text>
            <div class="checkout-faq-text" v-html="question.text"></div>
          </template>
        </VExpansionPanel>
      </VExpansionPanels>
    </div>
  </div>
</template>

<script lang="ts" setup>
const questions = [
  {
    title: "A correção é confiável?",
    text: `Sim! A inteligência artificial que corrige sua redação foi treinada pela Professora Raquel de Lima, que possui anos de experiência na correção das redações do ENEM.`,
  },
  {
    title: "Quem corrige as redações?",
    text: `Sua redação é corrigida pela Inteligência Artificial do coRedação. Além de dar uma nota por competência, ela também faz comentários sobre o seu texto, dá sugestões de melhorias e apresenta métricas comparando-o com redações nota 1000.`,
  },
  {
    title: "Posso enviar foto da minha redação para que ela seja corrigida?",
    text: `No momento, a correção por foto não está disponível. Para corrigir redações, é necessário digitá-las diretamente na plataforma.`,
  },
  {
    title: "Quantas redações posso enviar para correção?",
    text: `Você poderá enviar redações de forma ilimitada.`,
  },
  {
    title: "Em quanto tempo a redação será corrigida?",
    text: `No coRedação, sua redação é corrigida em segundos. Dessa forma, você não sente ansiedade ao esperar pela nota e evolui mais rápido!`,
  },
  {
    title: "É possível cancelar a assinatura a qualquer momento?",
    text: `Se você não estiver satisfeito com o serviço oferecido, é possível cancelar a compra e receber o reembolso do valor em até 7 dias após a data da compra. Após esse período, não realizamos cancelamentos.`,
  },
  {
    title: "Qual a diferença entre a versão gratuita e o plano pago?",
    text: `Na versão gratuita, você pode corrigir sua redação, ver a nota e marcações básicas no texto.<br /><br />
           Na versão paga você pode:
           <ul>
             <li>Ver as notas por competência</li>
             <li>Ter acesso as marcações dos erros avançados encontrados no texto</li>
             <li>Ler os comentários com sugestões de melhoria</li>
             <li>Ativar o modo “redação guiada”, passo a passo prático que te ajuda a escrever a redação do zero</li>
             <li>Analisar as estatísticas da redação</li>
             <li>Acessar o curso Redação de Sucesso, com aulas direto ao ponto que te mostram como escrever frase por frase do texto</li>
             <li>Tirar dúvidas a qualquer momento com a Prof Raquel</li>
           </ul>`,
  },
  {
    title: "Posso compartilhar minha conta com outra pessoa?",
    text: `Não. A conta é de uso pessoal e intransferível, sendo proibido vender, emprestar ou compartilhar a mesma.`,
  },
  {
    title: "O pagamento é feito por assinatura?",
    text: `Não! O plano possui pagamento único que pode ser parcelado no cartão de crédito. O método de pagamento não é por assinatura, portanto não é possível cancelar a qualquer momento.`,
  },
  {
    title: "Como usar o coRedação na minha escola?",
    text: `Caso queira oferecer o coRedação aos seus alunos entre em contato pelo <a href="https://coredacao.com/whatsapp-escola" target="_blank">nosso WhatsApp</a>.`,
  },
  {
    title: "Ainda tenho dúvidas, me ajuda!",
    text: `Se sua dúvida não foi respondida, envie uma mensagem <a href="https://coredacao.com/whatsapp-suporte" target="_blank">aqui.</a>`,
  },
];
</script>

<style lang="sass">
.checkout-faq-text
  ul
    list-style-type: disc
    padding-left: 20px
    li
      @apply my-1
</style>
