<template>
  <div class=" w-full h-full">
    <div class="">
      <div class="container relative">
        <!-- Close -->
        <VBtn
          icon
          :size="display.smAndDown.value ? '32px' : '48px'"
          class="z-50 fixed top-2 right-2 md:right-6 rounded-full bg-white !border-[1px] !border-accent/50 w-8 h-8 flex justify-center items-center shadow-2xl"
          @click="closeCheckout"
        >
          <VIcon :size="display.smAndDown.value ? 18 : 24" icon="mdi-close" />
        </VBtn>

        <VTabsWindow v-model="currentStep">
          <VTabsWindowItem :value="0">
            <CheckoutHero />
          </VTabsWindowItem>
          <VTabsWindowItem :value="1">
            <CheckoutPayment />
          </VTabsWindowItem>
          <VTabsWindowItem :value="2">
            <CheckoutPaymentPixHandler v-if="currentStep === 2" />
          </VTabsWindowItem>
        </VTabsWindow>
      </div>
    </div>
    <div class="bg-whites pb-20" v-if="currentStep === 0">
      <hr class="w-full mb-4" />

      <div class="container flex flex-col justify-center items-center">
        <CheckoutStats />
        <CheckoutBenefits />
        <CheckoutSocialProof />
        <CheckoutFaq />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCheckout } from "../composables/useCheckout";
import { CheckoutPaymentPixHandler } from "./payment";
import {
  CheckoutBenefits,
  CheckoutFaq,
  CheckoutHero,
  CheckoutPayment,
  CheckoutSocialProof,
  CheckoutStats,
} from ".";
import { useDisplay } from "vuetify";

const { currentStep, closeCheckout, init } = useCheckout();

onBeforeMount(init);

useHead({
  script: [{ src: "https://assets.pagar.me/pagarme-js/4.5/pagarme.min.js" }],
});

const display = useDisplay();
</script>
