<template>
  <div class="flex flex-col gap-2 items-center">
    <div class="relative">
      <img class="w-[250px] h-[100px]" src="/images/laurel.svg" />

      <div class="flex flex-col whitespace-nowrap absolute top-6 left-1/2 -translate-x-1/2 font-semibold text-lg">
        <span>#1 Correção por IA</span>
        <div class="flex justify-center mt-1 gap-1">
          <img v-for="i in 5" src="/images/icons/star.png" class="size-6" />
        </div>
      </div>
    </div>
    <div class="relative max-w-lg ">

      <div class="text-center flex gap-1 flex-col  font-medium text-lg">

        <span>Junte-se aos mais de 10 mil alunos que aumentaram suas notas com o coRedação PREMIUM.</span>
      </div>
    </div>
  </div>
</template>