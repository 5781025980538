<template>
  <VSelect
    v-model="model"
    label="Parcelas"
    variant="outlined"
    color="primary"
    density="comfortable"
    rounded
    :items="items"
  />
</template>

<script lang="ts" setup>
const props = defineProps<{
  maxInstallments: number
}>()

const items = computed(() => {
  const items = []
  for (let i = 1; i <= props.maxInstallments; i++) {
    items.push({
      title: `${i}x`,
      value: i,
    })
  }
  return items
})

const model = defineModel<number>()
</script>
