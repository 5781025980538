import { useMutation, useQueryClient } from '@tanstack/vue-query'
import dayjs from 'dayjs'
import { toast } from 'vue3-toastify'
import { useCheckout } from '../composables/useCheckout'
import { useAuth } from '~/domains/auth/composables/useAuth'
import { useApi } from '~/domains/global/composables/useApi'
import type { PaymentData } from '~/domains/account/queries/listPaymentsQuery'

export const usePaymentCreditCardMutation = () => {
  const queryClient = useQueryClient()
  const config = useRuntimeConfig()
  const { data: AuthData } = useAuth()
  const router = useRouter()
  const { hasCreditCardError } = useCheckout()

  return useMutation({
    mutationFn: async (params: {
      cpf: string
      cardNumber: string
      cardName: string
      cardExpiration: string
      cardCvv: string
      installments: number
      planId: string
      affiliateId: string | null
    }) => {
      hasCreditCardError.value = false
      const api = useApi()

      // Parse CPF
      params.cpf = params.cpf.replace(/\D/g, '')

      // Validate card
      const cardDetails = validatePagarmeCard(params)

      // Encrypt card data
      // @ts-expect-error - Pagar.me types are not available
      const client = await pagarme.client.connect({
        encryption_key: config.public.pagarMeKey,
      })
      const cardHash = await client.security.encrypt(cardDetails)

      try {
        const data = await api<PaymentData>(`/billing/card/create`, {
          method: 'POST',
          body: JSON.stringify({
            cpf: params.cpf,
            cardHash,
            installments: params.installments,
            affiliateId: params.affiliateId,
            planId: params.planId,
          }),
        })

        return data
      }
      catch (e: any) {
        if (e.response?._data?.transaction) {
          hasCreditCardError.value = true
        }
        throw new Error('Seu pagamento foi recusado pelo banco.')
      }
    },
    onError: (error) => {
      console.log(error)
      toast.error(error.message)
    },
    onSuccess: async (data) => {
      // Update user premium status
      const newSubscriptionDate = dayjs().add(data.planMonths, 'month')

      AuthData.value = {
        ...AuthData.value!,
        subscriptionDate: newSubscriptionDate.format(),
      }

      await queryClient.invalidateQueries({
        queryKey: ['payments'],
      })
      await queryClient.invalidateQueries({
        queryKey: ['reauth'],
      })

      router.push('/obrigado')
    },
  })
}

const validatePagarmeCard = (card: {
  cardName: string
  cardExpiration: string
  cardNumber: string
  cardCvv: string
}) => {
  // Validate card
  const cardDetails = {
    card_holder_name: card.cardName,
    card_expiration_date: card.cardExpiration,
    card_number: card.cardNumber,
    card_cvv: card.cardCvv,
  }
  // @ts-expect-error - Pagar.me types are not available
  const { card: cardValidations } = pagarme.validate({ card: cardDetails })

  if (!cardValidations.card_cvv) {
    throw new Error('CVV inválido')
  }

  if (!cardValidations.card_expiration_date) {
    throw new Error('Data de expiração inválida')
  }

  if (!cardValidations.card_holder_name) {
    throw new Error('Nome do titular do cartão inválido')
  }

  if (!cardValidations.card_number) {
    throw new Error('Número do cartão inválido')
  }

  return cardDetails
}
