<template>
  <div class="mt-10 w-full">
    <div class="text-center" v-if="false">
      <h3 class="font-quincy text-2xl">Veja os benefícios</h3>
      <p>Diversos benefícios para você chegar na sua nota dos sonhos.</p>
    </div>

    <!-- Benefits -->
    <div
      class="grid grid-cols-1 gap-y-10 gap-6 md:grid-cols-2 mt-4 max-w-4xl mx-auto"
    >
      <div
        v-for="benefit in benefits"
        :key="benefit.title"
        class="border-[1px] border-black/30 shadow rounded-xl px-3 py-6 md:!px-4"
      >
        <div class="inline-flex flex-col items-center w-full gap-2">
          <img :src="benefit.icon" class="size-12" />
          <h5 class="font-semibold text-center mt-2 text-xl md:text-xl">
            {{ benefit.title }}
          </h5>
        </div>
        <hr class="my-4" />
        <p v-html="benefit.text" class="text-center text-gray-600"></p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const benefits = [
  {
    title: "Não deixe nada passar",
    text: "Descubra suas falhas antes da prova com a <b>detecção avançada</b> de erros.",
    icon: "/images/checkout/benefits/correction.svg",
  },
  {
    title: "Gabarite as competências",
    text: "Receba <b>comentários detalhados</b> com explicações claras sobre seus acertos, erros e sugestões de melhorias. ",
    icon: "/images/checkout/benefits/models.svg",
  },
  {
    title: "Perca o medo da folha em branco",
    text: "Use o <b>Modo Redação Guiada</b> e siga um guia frase a frase de como escrever uma redação de sucesso.",
    icon: "/images/icons/write-guided.svg",
  },
  {
    title: "Aprenda a escrever sobre qualquer tema",
    text: "Assista aulas práticas e objetivas no curso <b>Redação de Sucesso</b> e aprenda a escrever uma redação sobre qualquer tema do zero.",
    icon: "/images/checkout/benefits/classes.svg",
  },
  {
    title: "Estrutura nota 1000",
    text: "Veja as <b>métricas de correção</b> da sua Redação e compare seu texto com redações nota 1000.",
    icon: "/images/checkout/benefits/grades.svg",
  },
  {
    title: "Seja prioridade",
    text: "Tenha sua redação <b>corrigida instantaneamente</b> sem filas e tenha suporte exclusivo com a professora <b>Raquel de Lima</b> no WhatsApp.",
    icon: "/images/checkout/benefits/chat.svg",
  },
];
</script>
