<template>
  <VDialog
    :model-value="isOpen"
    :close-on-back="false"
    fullscreen
    transition="dialog-bottom-transition"
    @after-leave="closeCheckout"
  >
    <div class="max-h-[100dvh] h-full overflow-auto bg-white">
      <CheckoutRoot />
    </div>
  </VDialog>
</template>

<script lang="ts" setup>
import { useCheckout } from "../composables/useCheckout";
import { CheckoutRoot } from ".";

const { isOpen, closeCheckout } = useCheckout();
</script>
