<template>
  <div
    v-if="currentPaymentData"
    class="max-w-md mx-auto my-14"
  >
    <h1 class="font-quincy text-4xl md:text-5xl">
      Pague com Pix
    </h1>
    <div class="hidden md:block mt-4 text-lg">
      <h2>
        Escaneie este código para pagar.
      </h2>

      <ol class="mb-4">
        <li>
          <b>1. </b>Abra o aplicativo do seu banco.
        </li>
        <li>
          <b>2. </b>Selecione a opção Pagar com Pix.
        </li>
        <li>
          <b>3. </b>Escaneie o código abaixo.
        </li>
      </ol>

      <QrcodeVue
        :value="currentPaymentData.pixQrcode"
        level="H"
        render-as="svg"
        class="w-full h-[200px]"
      />

      <hr class="mt-10 mb-4 border-[1px] border-accent-1">
      <h2>
        Ou copie o código abaixo para pagar com PIX copia e cola.
      </h2>

      <VTextField
        :model-value="currentPaymentData.pixQrcode"
        label="Chave PIX"
        variant="outlined"
        color="primary"
        density="comfortable"
        rounded
        readonly
        class="mt-4"
        @click.stop="copyCode"
      >
        <template #append-inner>
          <VBtn
            size="small"
            color="primary"
            rounded="full"
            @click.stop="copyCode"
          >
            Copiar
          </VBtn>
        </template>
      </VTextField>
    </div>

    <div>
      <h2 class="mb-4">
        Copie o código abaixo. Em seguida, você precisará:
      </h2>

      <ol class="mb-8 flex flex-col gap-2">
        <li>
          <b>1. </b>Abrir o aplicativo do seu banco.
        </li>
        <li>
          <b>2. </b>Selecionar a opção Pagar com Pix.
        </li>
        <li>
          <b>3. </b>Colar o código abaixo e concluir o pagamento.
        </li>
      </ol>

      <VTextField
        :model-value="currentPaymentData.pixQrcode"
        variant="outlined"
        color="primary"
        density="comfortable"
        rounded
        readonly
        hide-details
        class="mt-4"
        @click="copyCode"
      />

      <VBtn
        size="large"
        block
        color="primary"
        rounded="full"
        class="my-4"
        @click="copyCode"
      >
        Copiar
      </VBtn>
    </div>

    <VAlert
      color="primary"
      theme="dark"
      prominent
      class="text-sm rounded-lg"
    >
      <template #prepend>
        <VProgressCircular
          indeterminate
          :size="18"
        />
      </template>
      Seu acesso premium será liberado em alguns segundos após o pagamento.
    </VAlert>
  </div>
</template>

<script setup lang="ts">
import QrcodeVue from 'qrcode.vue'
import { toast } from 'vue3-toastify'
import { useQueryClient } from '@tanstack/vue-query'
import { useCheckout } from '../../composables/useCheckout'
import { getPaymentById } from '../../queries/getPaymentById'
import { PaymentState } from '~/domains/account/queries/listPaymentsQuery'
import { useAuth } from '~/domains/auth/composables/useAuth'
import dayjs from '~/domains/global/libs/dayjs'

const { currentPaymentData } = useCheckout()
const { data: AuthData } = useAuth()

const queryClient = useQueryClient()
const router = useRouter()

const copyCode = () => {
  navigator.clipboard.writeText(currentPaymentData.value!.pixQrcode)

  toast.success('Código copiado!')
}

onMounted(() => {
  checkPaymentStatus()
})

onUnmounted(() => {
  currentPaymentData.value = null
})

const checkPaymentStatus = async () => {
  if (!currentPaymentData.value) {
    setTimeout(() => {
      checkPaymentStatus()
    }, 5000)
  }

  if (currentPaymentData.value?.state !== PaymentState.WAITING_PAYMENT) {
    return
  }
  // check payment status
  const paymentData = await queryClient.fetchQuery({
    ...getPaymentById(currentPaymentData.value.id),
  })

  // Handle payment success
  if (paymentData.state === PaymentState.PAID) {
    currentPaymentData.value = paymentData

    // Update subscription date
    const newSubscriptionDate = dayjs().add(paymentData.planMonths, 'month')
    AuthData.value = {
      ...AuthData.value!,
      subscriptionDate: newSubscriptionDate.format(),
    }

    // Invalidate queries & redirect
    await queryClient.invalidateQueries({
      queryKey: ['payments'],
    })

    router.push('/obrigado')
    return
  }

  setTimeout(() => {
    checkPaymentStatus()
  }, 5000)
}
</script>
